<template>
    <div class="content">
        <el-row style="margin-bottom: 20px">我的訂單</el-row>
        <el-row>
            <el-button type="info" plain size="mini" @click="listOrder(0)" align="right">待付款</el-button>
            <el-button type="primary" plain size="mini" @click="listOrder(1)" align="right">待出貨</el-button>
            <el-button type="warning" plain size="mini" @click="listOrder(2)" align="right">待收貨</el-button>
            <el-button type="success" plain size="mini" @click="listOrder(3)" align="right">已收貨</el-button>
            <el-button type="success" plain size="mini" @click="listOrder(4)" align="right">已完成</el-button>
            <el-button type="danger" plain size="mini" @click="listOrder(6)" align="right">已取消</el-button>
            <el-button type="danger" plain size="mini" @click="listOrder(-3)" align="right">售後/退款</el-button>
        </el-row>
        <el-divider class="order-divider"></el-divider>
        <div v-if="orderList.length>0">
            <el-row style="font-weight: bold;color: #909399;margin-bottom: 10px;">
                <el-col :lg="3" :xs="4" align="center">操作</el-col>
                <el-col :lg="6" :xs="14" style="text-align: center;">訂單編號<br/>訂購時間</el-col>
                <el-col :lg="5" class="hidden-sm-and-down" style="text-align: center">付款方式</el-col>
                <el-col :lg="6" class="hidden-sm-and-down" style="text-align: center">缴款/匯款帳號<br/>繳款期限</el-col>
                <el-col :lg="4" :xs="6" style="text-align: center">總計</el-col>
            </el-row>
            <div>
                <el-row class="order-item-list" v-for="(item,index) in orderList" :key="index" type="flex" justify="space-between">
                    <el-col :lg="3" :xs="5" style="display: flex;flex-direction: column;">
                        <el-button v-show="item.showToPay" style="padding: 5px;" size="mini" type="warning" icon="el-icon-bank-card"
                                   @click="go2pay(item)" title="去付款">去付款</el-button>
                        <el-button v-show="item.showToDetail" style="padding: 5px;margin: 2px 0 0px 0;" size="mini" type="success" icon="el-icon-document"
                                   @click="go2Dtl(item)" title="查看詳情">看詳情</el-button>
                        <el-button v-show="item.showToDel" style="padding: 5px;margin: 2px 0 2px 0;" size="mini" type="danger" icon="el-icon-delete"
                                   @click="go2Del(item)" title="删除訂單">删除</el-button>
                    </el-col>
                    <el-col :lg="6" :xs="13" style="text-align: center;display: flex;align-items: center;justify-content:center;">{{item.orderId}}<br/>{{item.createTime}}</el-col>
                    <el-col :lg="5" class="hidden-sm-and-down" style="text-align: center;display: flex;align-items: center;justify-content:center;flex-direction: column;">
                        <span v-if="item.payTypeCode=='ATM'">ATM虛擬帳號轉帳</span>
                        <span v-if="item.payTypeCode=='CVS'">{{item.merBanks | filter_payChannel}}</span>

                        <span>
                            （{{item.orderStatusStr}}）
                        </span>
                    </el-col>
                    <el-col :lg="6" class="hidden-sm-and-down" style="text-align: center;display: flex;align-items: center;justify-content:center;">{{item.paymentCode}}<br/>{{item.payExpireDate}}</el-col>
                    <el-col :lg="4" :xs="6" class="total-amt" style="text-align: center;display: flex;align-items: center;justify-content:center;">{{item.payPrice |filter_addPricePrefix}}</el-col>
                </el-row>
            </div>
            <el-row style="text-align: center;">
                <el-pagination
                        small background
                        @current-change="handCurrentChange"
                        @prev-click="prevClick"
                        @next-click="nextClick"
                        :hide-on-single-page="true"
                        :page-size="limit"
                        layout="prev, pager, next"
                        :total="total">
                </el-pagination>
            </el-row>
        </div>
        <div v-else v-show="!emptFlg">
            <el-skeleton />
        </div>
        <div v-show="emptFlg">
            <el-empty :image-size="100" description="暫時沒有資料">
            </el-empty>
        </div>
    </div>
</template>

<script>
    import {OrderList, OrderPayment, OrderDel} from '@/request/api';
    export default {
        components: {},
        data() {
            return {
                emptFlg:false,
                type: 0,
                limit: 10,
                page: 1,
                total:0,
                orderList: [],
            }
        },
        methods: {
            getOrderList() {
                let _this = this;
                // const loading = this.$loading({
                //     lock: true,
                //     text: "加載中...",
                //     spinner: "el-icon-loading",
                //     background: "rgba(0, 0, 0, 0.5)"
                // });
                OrderList(this.type, {limit: this.limit, page: this.page}).then(res => {
                    //loading.close();
                    if (res.code == 200) {
                        _this.orderList = res.data.list;
                        if (_this.orderList.length <= 0) {
                            _this.emptFlg=true;
                        }else{
                            _this.emptFlg=false;
                        }
                        this.total=res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    //loading.close();
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            listOrder(status) {
                this.type = status;
                this.page = 1;
                this.getOrderList();
            },
            go2cart() {
                this.$router.push('/cart');
            },
            go2pay(order) {
                // let _this = this;
                OrderPayment({
                    orderNo: order.orderId,
                    payType: order.payType,
                    payChannel: order.payChannel,
                }).then(res => {
                    if (res.code == 200 && res.data.status == true) {
                        /*var div = document.createElement('div');
                        div.innerHTML = res.data.szfuPayForm;
                        document.body.appendChild(div);
                        document.forms.szfuForm.submit();*/
                        window.location.href = res.data.szfuPayRes.url;
                    } else {
                        this.$message.error(res.message);
                        this.$router.push('/user-center/orders');
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            go2Dtl(item) {
                this.$router.push('/checkOutRcv?tx=' + item.orderId);
            },
            go2Del(item) {
                this.$confirm('確定要删除當前訂單嗎？訂單號：' + item.orderId).then(() => {
                    const params = new URLSearchParams();
                    params.append("id", item.id);
                    OrderDel(params).then(res => {
                        if (res.code == 200) {
                            this.dialogFormVisible = false;
                            this.$message.success("删除成功！");
                            this.$router.go(0);
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.$message.error('網絡請求失敗，稍候請重試!');
                    });
                }).catch(() => {
                });
            },
            handCurrentChange(current){
                this.page=current;
                this.getOrderList();
            },
            prevClick(){
                this.page = this.page - 1;
            },
            nextClick(){
                this.page = this.page + 1;
            }
        },
        mounted() {
            this.getOrderList();
        },
    }
</script>
<style lang="less" scoped>
    .content{
        padding: 10px;
    }
    .order-item-list{
        font-size: 14px;
        margin-bottom: 10px;
        padding-bottom: 5px;
        border-bottom-style: inset;
        border-bottom-width: 1px;

        &:last-child {
            border-bottom-width: 0px;
            margin-bottom: 0px;
        }
    }
    .total-amt {
        color: #a73535;
        font-weight: bold;
    }
    .order-divider{
        margin: 10px auto;
    }
</style>
